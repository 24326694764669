import ReactGA from 'react-ga';
import actionTypes from './types';
import {
  HTTP,
  favoriteTypes,
  keys,
  settings,
  endPoint,
  paymentType,
  tatsApiPosUrl,
} from '../../../services/constants';
import apiCall, {
  bucketCall,
  contractBucketCallBulk,
  tongucAkademiApiCall,
} from '../../../services/api';
import storage from '../../../services/storage';
import { history } from '../../../configureStore';
import * as productActions from '../Product';

import { toast } from 'react-toastify';

export const login = (credentials) => async (dispatch) => {
  try {
    const { data } = await apiCall(
      '/token',
      credentials,
      null,
      HTTP.POST,
      null,
      false,
      true,
    );
    if (data.status == false) {
      return { success: false, error: data.message, inActive: data.inActive };
    }

    return await loginHelper(data, dispatch);

    return { success: true };
  } catch (error) {
    return { success: false, error: error.data };
  }
};

export const loginExchange = (token) => async (dispatch) => {
  try {
    const { data } = await apiCall(
      '/token-exchange?queryToken=' + token,
      null,
      null,
      HTTP.GET,
      null,
      false,
      true,
    );
    if (data.status == false) {
      return { success: false, error: data.message, inActive: data.inActive };
    }

    return await loginHelper(data, dispatch);

    return { success: true };
  } catch (error) {
    return { success: false, error: error.data };
  }
};

export const refreshUserInStorage = () => async (dispatch) => {
  try {
    const { data } = await apiCall(
      `/account`,
      null,
      null,
      HTTP.GET,
      null,
      true,
    );
    storage.setUser(data);

    dispatch({
      type: actionTypes.UPDATE_USER_PROFILE,
      payload: data,
    });
    return { success: true };
  } catch (error) {
    return { success: false, error: error.data };
  }
};

async function loginHelper(data, dispatch) {
  try {
    data.user.isClassUpdated =
      data.user.isClassUpdated === undefined ? null : data.user.isClassUpdated;

    storage.setAuth(data);

    ReactGA.set({
      userId: data.user.id,
    });

    ReactGA.event({
      category: 'Login',
      action: `UserID: ${data.user.id}`,
    });

    //GET NOTIFICATIONS
    const notifications = await apiCall(
      '/notifications',
      null,
      null,
      HTTP.GET,
      null,
      true,
    );

    dispatch({
      type: actionTypes.LOGIN,
      payload: {
        login: {
          ...data,
          // contracts: [],
          // contractsNoContent: [],
          // unsignedContracts: [],
        },
        notifications: notifications.data,
      },
    });

    //GET CONTRACTS
    const contracts = await apiCall(
      `/contracts/unsigned`,
      null,
      null,
      HTTP.GET,
      null,
      true,
    );

    var result = await contractBucketCallBulk(contracts.data);
    for (let i = 0; i < result.length; i++) {
      contracts.data[i].content = result[i].data.content;
    }

    dispatch({
      type: actionTypes.GET_UNSIGNED_CONTRACTS,
      payload: contracts.data,
    });

    var lastLocation = storage.getItem(keys.LastLocation);
    if (lastLocation) {
      history.push(lastLocation);
      storage.removeItem(keys.LastLocation);
    }

    var lastUserId = storage.getItem(keys.LastUserId) || 0;
    var currentUserId = data.user.id || 0;
    if (
      lastUserId > 0 &&
      currentUserId > 0 &&
      Number(lastUserId) !== currentUserId
    ) {
      storage.removeItem(keys.Basket);
      storage.removeOrderCode();
      storage.removeItem(keys.BasketCount);
      dispatch({
        type: actionTypes.DELETE_ALL_BASKET,
      });
    }
    storage.setItem(keys.LastUserId, currentUserId);

    return { success: true };
  } catch (error) {
    throw error;
  }
}

export const loginAsDemoUser = () => async (dispatch) => {
  try {
    //GET TOKEN
    const { data } = await apiCall(
      '/token/demo',
      null,
      null,
      HTTP.GET,
      null,
      false,
    );
    storage.setAuth(data);

    dispatch({
      type: actionTypes.LOGIN,
      payload: {
        login: data,
        notifications: [],
      },
    });

    return { success: true };
  } catch (error) {
    return { success: false, error: error.data };
  }
};

export const getIsEmailVerified = () => async (dispatch) => {
  try {
    var isWaitingForEmailVerification =
      storage.getWaitingForEmailVerification();
    if (!isWaitingForEmailVerification) return;

    const { data } = await apiCall(
      '/account/isemailverified',
      null,
      null,
      HTTP.GET,
      null,
      true,
    );

    dispatch({
      type: actionTypes.GET_IS_EMAIL_VERIFIED,
      payload: {
        isEmailVerified: data.isEmailVerified,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateClass = (userId, formData) => async (dispatch) => {
  try {
    if (formData.classId === 0) {
      formData.classId = null;
    }
    await apiCall(
      `/account/${userId}/class`,
      formData,
      null,
      HTTP.POST,
      null,
      true,
    );

    dispatch({
      type: actionTypes.UPDATE_CLASS,
      payload: {
        ...formData,
      },
    });

    return { success: true };
  } catch (error) {
    return { success: false, error: 'Bir hata oluştu' };
  }
};

export const logoutDemoUser = () => async (dispatch, getState) => {
  var currentState = getState();
  if (currentState.AccountReducer.user.demo === true) {
    storage.removeAuth();

    dispatch({
      type: actionTypes.LOGOUT,
    });
  }
};

export const logout = (history) => (dispatch) => {
  storage.removeAuth();

  dispatch({
    type: actionTypes.LOGOUT,
  });

  history.push('/');
};

export const register = (user) => async () => {
  try {
    const { data } = await apiCall(
      '/account',
      user,
      null,
      HTTP.POST,
      null,
      false,
      true,
    );

    storage.removeAuth();

    return { success: true, data };
  } catch (error) {
    if (error.status === 400) {
      return { success: false, error: error.data };
    }
    return { success: false, error: error.response.data };
  }
};

export const changePassword = (user, passwordModel) => async () => {
  try {
    await apiCall(
      `/account/${user.id}/password`,
      passwordModel,
      null,
      HTTP.POST,
      null,
      true,
    );

    return { success: true };
  } catch (error) {
    if (error.status === 400) {
      return { success: false, error: error.data };
    }
    return { success: false, error: error.data };
  }
};

export const remindPassword = (email) => async () => {
  try {
    await apiCall('/account/remind', email, null, HTTP.POST, null, false);

    return { success: true };
  } catch (error) {
    return { success: false, error: error.data };
  }
};

export const changePreferences = (user, type, value) => async (dispatch) => {
  const preference = {
    preferenceType: type,
    value,
  };

  await apiCall(
    `/account/${user.id}/preferences`,
    preference,
    null,
    HTTP.PUT,
    null,
    true,
  );

  storage.setUser(user);

  dispatch({
    type: actionTypes.CHANGE_PREFERENCES,
    payload: user,
  });
};

export const handleShowQrScanner = (show) => async (dispatch) => {
  dispatch({
    type: show
      ? actionTypes.SHOW_QR_SCANNER_MODAL
      : actionTypes.HIDE_QR_SCANNER_MODAL,
  });
};

export const updateUserProfile = (user) => async (dispatch) => {
  try {
    user.cityId = user.city?.id ?? user.cityId;
    user.townId = user.town?.id ?? user.townId;
    await apiCall(`/account/${user.id}`, user, null, HTTP.PUT, null, true);

    storage.setUser(user);

    dispatch({
      type: actionTypes.UPDATE_USER_PROFILE,
      payload: user,
    });

    return { success: true };
  } catch (error) {
    if (error.status === 422) {
      return { success: false, error: error.data.error };
    }
    return { success: false, error: error.response.data };
  }
};

export const sendVerifyEmail =
  (email, source = '') =>
  async () => {
    try {
      await apiCall(
        '/verification/email' + (source ? '?source=' + source : ''),
        email,
        null,
        HTTP.POST,
        null,
        true,
      );
      storage.setWaitingForEmailVerification(true);
      return { success: true };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  };

export const sendVerifySms = (phone) => async () => {
  try {
    await apiCall('/verification/sms', phone, null, HTTP.POST, null, true);

    return { success: true };
  } catch (error) {
    return { success: false, error: error.data };
  }
};

export const checkResetPaswordKey = (key) => async () => {
  try {
    var result = await apiCall(
      '/account/check',
      { data: key },
      null,
      HTTP.POST,
      null,
      false,
    );

    return { success: true, error: result.data };
  } catch (error) {
    return { success: false, error: error.data };
  }
};

export const changePasswordWithKey = (data) => async () => {
  try {
    await apiCall(
      `/account/update-password`,
      data,
      null,
      HTTP.POST,
      null,
      false,
    );

    return { success: true, error: '' };
  } catch (error) {
    if (error.status === 400) {
      return { success: false, error: error.data };
    }
    return { success: false, error: error.data };
  }
};

export const verify = (model) => async (dispatch) => {
  try {
    if (model.mobile) {
      model.mobile = model.mobile.replace(/ /g, '');
    }

    await apiCall('/verification', model, null, HTTP.POST, null, true);

    dispatch({
      type: actionTypes.VERIFIED,
      payload: {
        mobile: model.mobile ? true : false,
      },
    });

    if (!model.mobile) {
      var mailVerificationRedirectLocation = storage.getItem(
        keys.MailVerificationRedirectLocation,
      );
      if (mailVerificationRedirectLocation) {
        storage.removeItem(keys.MailVerificationRedirectLocation);
        history.push(mailVerificationRedirectLocation);
        toast.success('Doğrulama işlemi başarılıyla sonuçlandı.');
      }
    }

    return { success: true };
  } catch (error) {
    return {
      success: false,
      error: typeof error.data === 'string' ? error.data : error.response.data,
    };
  }
};

/* export const getIssueTypesAndCategories = () => async (dispatch) => {

    const issueTypes = apiCall("/issue/types", null, null, HTTP.GET, null, false);
    const issueCategories = apiCall("/issue/categories", null, null, HTTP.GET, null, false);

    const issues = await issueTypes;
    const categories = await issueCategories;

    dispatch({
        type: actionTypes.GET_ISSUE_TYPES_AND_CATEGORIES,
        payload: {
            issues: issues.data,
            categories: categories.data,
        }
    });
} */

export const getIssueTypes = () => async (dispatch) => {
  const issueTypes = apiCall(
    '/issue/taxonomies',
    null,
    null,
    HTTP.GET,
    null,
    false,
  );

  const issues = await issueTypes;

  dispatch({
    type: actionTypes.GET_ISSUE_TYPES,
    payload: {
      issues: issues.data,
    },
  });
};

export const getIssueCategories = () => async (dispatch) => {
  const issueTypes = apiCall(
    '/issue/crmCategories',
    null,
    null,
    HTTP.GET,
    null,
    false,
  );

  const issues = await issueTypes;

  dispatch({
    type: actionTypes.GET_ISSUE_TYPES,
    payload: {
      issues: issues.data,
    },
  });
};

export const createCrmIssue = (issue) => async (dispatch) => {
  try {
    const { data } = await apiCall(
      '/issue/crmCreate',
      issue,
      null,
      HTTP.POST,
      null,
      true,
    );

    dispatch({
      type: actionTypes.CREATE_ISSUE,
      payload: data,
    });
    return { success: true, data };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const createIssue = (issue) => async (dispatch) => {
  try {
    const { data } = await apiCall(
      '/issue',
      issue,
      null,
      HTTP.POST,
      null,
      true,
    );

    dispatch({
      type: actionTypes.CREATE_ISSUE,
      payload: data,
    });
    return { success: true, data };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const getReservations = () => async (dispatch) => {
  const { data } = await apiCall(
    '/reservations',
    null,
    null,
    HTTP.GET,
    null,
    true,
  );

  dispatch({
    type: actionTypes.GET_RESERVATIONS,
    payload: data,
  });
};

export const getAddresses = () => async (dispatch) => {
  const { data } = await apiCall('/addresses');

  dispatch({
    type: actionTypes.GET_ADDRESSES,
    payload: data,
  });
};

export const createAddress = (address) => async (dispatch) => {
  try {
    const { data } = await apiCall(
      '/addresses',
      address,
      null,
      HTTP.POST,
      null,
      true,
    );

    dispatch({
      type: actionTypes.CREATE_ADDRESS,
      payload: data,
    });

    return { success: true, data };
  } catch (e) {
    return { success: false, error: e };
  }
};

const getContractsData = async () => {
  const { data } = await apiCall(`/contracts`);

  var result = await contractBucketCallBulk(data);
  for (let i = 0; i < result.length; i++) {
    data[i].content = result[i].data.content;
  }
  return data;
};

export const getContracts = () => async (dispatch, getState) => {
  try {
    const data = await getContractsData();

    dispatch({
      type: actionTypes.GET_CONTRACTS,
      payload: data,
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const getContractsNoContent = () => async (dispatch) => {
  try {
    const { data } = await apiCall(`/contracts/nocontent`);

    dispatch({
      type: actionTypes.GET_CONTRACTS_NO_CONTENT,
      payload: data,
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const getUnsignedContracts = () => async (dispatch) => {
  try {
    const { data } = await apiCall(
      `/contracts/unsigned`,
      null,
      null,
      HTTP.GET,
      null,
      true,
    );

    var result = await contractBucketCallBulk(data);
    for (let i = 0; i < result.length; i++) {
      data[i].content = result[i].data.content;
    }

    dispatch({
      type: actionTypes.GET_UNSIGNED_CONTRACTS,
      payload: data,
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const signContract = (contract) => async (dispatch) => {
  try {
    const { data } = await apiCall(
      `/contracts`,
      contract,
      null,
      HTTP.POST,
      null,
      true,
    );
    // toast.success(contract.contractName + "'ni kabul ettiniz.");

    dispatch({
      type: actionTypes.SIGN_CONTRACT,
      payload: contract.contractId,
    });

    return { success: true, message: data };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const updateAddress = (address) => async (dispatch, getState) => {
  try {
    await apiCall(
      `/addresses/${address.id}`,
      address,
      null,
      HTTP.PUT,
      null,
      true,
    );
    const addressIndex = getState().AccountReducer.addresses.findIndex(
      (a) => a.id === address.id,
    );

    dispatch({
      type: actionTypes.UPDATE_ADDRESS,
      payload: {
        index: addressIndex,
        address,
      },
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const deleteAddress = (address) => async (dispatch) => {
  try {
    await apiCall(
      `/addresses/${address.id}`,
      null,
      null,
      HTTP.DELETE,
      null,
      true,
    );

    dispatch({
      type: actionTypes.DELETE_ADDRESS,
      payload: address,
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const getMasterpassTokens = (params) => async (dispatch) => {
  const { data } = await apiCall(
    '/payment/masterpass/token',
    null,
    params,
    HTTP.GET,
    null,
    true,
  );

  dispatch({
    type: actionTypes.GET_MASTERPASS_TOKENS,
    payload: data,
  });
};

export const getOrders = () => async (dispatch) => {
  try {
    const { data } = await apiCall('/orders');

    data.forEach((order) => {
      order.parsedDate = new Date(order.date);
    });

    dispatch({
      type: actionTypes.GET_ORDERS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ORDERS,
      payload: [],
    });
  }
};

export const getCoinOrders = () => async (dispatch) => {
  try {
    const { data } = await apiCall('/coins/orders');

    data.forEach((order) => {
      order.parsedDate = new Date(order.date);
    });

    dispatch({
      type: actionTypes.GET_COIN_ORDERS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_COIN_ORDERS,
      payload: [],
    });
  }
};

export const fetchPayment = (orderId, paramsType) => async (dispatch) => {
  const contractsDataRequest = getContractsData();

  if (paramsType === 'sepet') {
    let orderRequest = apiCall(`/orders/${orderId}`);
    let addressesRequest = apiCall('/addresses');
    let shippingOptionsRequest = apiCall('/basket/shippinginfo');

    let contracts = await contractsDataRequest;
    let order = await orderRequest;
    let addresses = await addressesRequest;
    var allDigital = order.data.lines.every((line) => line.isDigital);

    let shippingOptions = allDigital
      ? { data: [] }
      : await shippingOptionsRequest;

    dispatch({
      type: actionTypes.FETCH_PAYMENT,
      payload: {
        contracts: contracts,
        order: order.data,
        addresses: addresses.data,
        shippingOptions: shippingOptions.data,
      },
    });

    return {
      paid: order.data.paid,
    };
  } else {
    let contracts = await contractsDataRequest;

    dispatch({
      type: actionTypes.FETCH_PAYMENT,
      payload: {
        contracts: contracts,
      },
    });

    return {
      paid: false,
    };
  }
};

export const getOrder =
  (
    orderId,
    ignoreUnAuthorizedError,
    couponIsReserved = false,
    code = '',
    mail = '',
  ) =>
  async (dispatch) => {
    try {
      const queryParams = new URLSearchParams({
        couponIsReserved,
        code,
        mail,
      }).toString();
      const { data } = await apiCall(
        `/orders/${orderId}?${queryParams}`,
        null,
        null,
        HTTP.GET,
        null,
        true,
        false,
        { ignoreUnAuthorizedError },
      );
      data.parsedDate = new Date(data.date);

      dispatch({
        type: actionTypes.GET_ORDER,
        payload: data,
      });

      return { success: true, order: data };
    } catch (error) {
      return { success: false, error: error };
    }
  };

export const checkCampaigns = (orderId) => async (dispatch) => {
  const { data } = await apiCall(`/orders/checkCampaigns/${orderId}`);

  return data;
};

export const getShippingOptions = () => async (dispatch) => {
  const { data } = await apiCall('/basket/shippinginfo');

  dispatch({
    type: actionTypes.GET_SHIPPING_OPTIONS,
    payload: data,
  });
};

export const pay = (orderId, payment) => async () => {
  try {
    const { data } = await apiCall(
      `/payment/pay/${storage.getOrderCode()}`,
      payment,
      null,
      HTTP.POST,
      null,
      true,
    );
    return { success: true, data };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const getCardPoint = (payment) => async () => {
  try {
    const { data } = await apiCall(
      `/payment/GetCardPoint`,
      payment,
      null,
      HTTP.POST,
      null,
      true,
    );
    return { success: true, data };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const indirimUygula = (indirimKupon) => async () => {
  try {
    const { data } = await apiCall(
      `/payment/indirimUygula`,
      indirimKupon,
      null,
      HTTP.POST,
      null,
      true,
    );
    return { success: true, data };
  } catch (e) {
    return { success: false, error: e };
  }
};
export const indirimVazgec = (indirimKupon) => async () => {
  try {
    const { data } = await apiCall(
      `/payment/indirimVazgec`,
      indirimKupon,
      null,
      HTTP.POST,
      null,
      true,
    );
    return { success: true, data };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const pageVisited =
  (orderId, discountCode = '', couponIsReserved = false) =>
  async () => {
    try {
      const { data } = await apiCall(
        `/orders/${orderId}/${storage.getOrderCode()}`,
        null,
        { discountCode: discountCode, couponIsReserved: couponIsReserved },
        HTTP.PUT,
        null,
        true,
      );
      return { success: true, data };
    } catch (e) {
      return { success: false, error: e };
    }
  };

export const payCoin = (packId, payment) => async () => {
  try {
    const { data } = await apiCall(
      `/payment/pay/coin/${packId}`,
      payment,
      null,
      HTTP.POST,
      null,
      true,
    );
    return { success: true, data };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const getCardInfo = (pan) => async () => {
  try {
    const { data } = await apiCall(
      `/payment/${pan}`,
      null,
      null,
      HTTP.GET,
      null,
      true,
    );

    return { success: true, data };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const loginWithJwtToken = (jwtToken) => async (dispatch) => {
  try {
    storage.setToken(jwtToken);
    //TODO: belki burada kullanıcı api'den istenip local storage'a kaydedilebilir.
    storage.setUser({});
    return { success: true };
  } catch (error) {
    console.error(error);
    return { success: false };
  }
};

export const mobilePay3d = (orderId, data) => async (dispatch) => {
  try {
    const mobileOrderApi =
      data.paymentUrl ||
      tatsApiPosUrl[process.env.REACT_APP_NODE_ENV] ||
      tatsApiPosUrl.Test;

    const getOrderRequest = await getMobileOrder(
      orderId,
      true,
      mobileOrderApi,
    )(dispatch);
    var message = 'sipariş başarıyla yüklendi';
    if (!getOrderRequest.success) {
      if (getOrderRequest.error.status == 401) {
        message = 'Kullanıcı bilgileri hatalı.';
      } else {
        message = getOrderRequest.error.statusText;
      }
      return { success: false, message };
    }

    // pay3d ile ödeme yapmak için sipariş kodu ile istek yapılır, öncesinde kaydetmek lazım
    storage.setOrderCode(data.code);

    const payment = {
      creditCardNumber: data.pan,
      expireMonth: data.month,
      expireYear: data.year,
      cvc: data.cvc,
      orderNumber: getOrderRequest.order.orderNumber,
      name: '',
      total: getOrderRequest.order.itemTotal + getOrderRequest.order.taxTotal,
      purchaseCount: data.installment,
      recommendationProducts: [],
      origin: mobileOrderApi,
      paymentMethod: paymentType.CreditCard,
      shippingOption: data.shippingOption,
      saveMasterpass: false,
      purchaseMasterpass: false,
      couponIsReserved: data.couponIsReserved,
      couponCode: data.couponCode,
      isListIndirim: data.isListIndirim,
      totalCardPoint: data.totalCardPoint,
    };

    const pay3dRequest = await pay3d(
      orderId,
      payment,
      mobileOrderApi,
    )(dispatch);
    return pay3dRequest;
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const getMobileOrder =
  (orderId, ignoreUnAuthorizedError, paymentUrl = undefined) =>
  async (dispatch) => {
    try {
      const mobileOrderApi =
        paymentUrl ||
        tatsApiPosUrl[process.env.REACT_APP_NODE_ENV] ||
        tatsApiPosUrl.Test;
      const { data } = await apiCall(
        `/orders/${orderId}`,
        null,
        null,
        HTTP.GET,
        null,
        true,
        false,
        { ignoreUnAuthorizedError },
        mobileOrderApi,
      );
      data.parsedDate = new Date(data.date);

      dispatch({
        type: actionTypes.GET_ORDER,
        payload: data,
      });

      return { success: true, order: data };
    } catch (error) {
      return { success: false, error: error };
    }
  };

export const pay3d =
  (orderId, payment, apiEndpoint = undefined) =>
  async () => {
    try {
      const { data } = await apiCall(
        `/payment/pay3d/${storage.getOrderCode()}`,
        payment,
        null,
        HTTP.POST,
        null,
        true,
        false,
        {},
        apiEndpoint,
      );

      return { success: true, data };
    } catch (e) {
      return { success: false, error: e };
    }
  };

export const pay3dcoin = (packId, payment) => async () => {
  try {
    const { data } = await apiCall(
      `/payment/pay3d/coin/${packId}`,
      payment,
      null,
      HTTP.POST,
      null,
      true,
    );
    return { success: true, data };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const payBkm = (orderId, payment) => async () => {
  try {
    const { data } = await apiCall(
      `/bkm/initticket?id=${storage.getOrderCode()}`,
      payment,
      null,
      HTTP.POST,
      null,
      true,
    );

    return { success: true, data };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const createOrder = (order) => async () => {
  try {
    const filteredOrder = {
      Lines: order.lines.map((line) => ({
        ProductId: line.productId,
        Quantity: line.quantity,
        RecommendationProducts: line.recommendationProducts,
      })),
    };

    let result = await apiCall(
      '/orders',
      filteredOrder,
      null,
      HTTP.POST,
      null,
      true,
    );

    if (typeof window !== 'undefined') {
      // gtm - datalayer

      if (window.dataLayer != null) {
      }
    }

    storage.setOrderCode(result.data.code);

    return { success: true, data: result.data };
  } catch (error) {
    return {
      success: false,

      error: error && error.response && error.response.data,
    };
  }
};

export const updateOrderAddresses = (order) => async (dispatch) => {
  try {
    const filteredOrder = {
      CustomerId: order.customerId,
      BillAddress: order.billAddress
        ? {
            Id: order.billAddress.id,
          }
        : null,
      DeliveryAddress: order.deliveryAddress
        ? {
            Id: order.deliveryAddress.id,
          }
        : null,
    };
    /* var result = */ await apiCall(
      `/orders/${order.id}`,
      filteredOrder,
      null,
      HTTP.PUT,
      null,
      true,
    );

    dispatch({
      type: actionTypes.UPDATE_ORDER,
      payload: order,
    });
    return { success: true };
  } catch (error) {
    return { success: false, error: error.data };
  }
};

export const getFavorites = () => async (dispatch) => {
  const { data } = await apiCall('/favorites/');
  dispatch({
    type: actionTypes.GET_FAVORITES,
    payload: {
      products: data.products,
      brands: data.brands,
      isLoaded: true,
    },
  });
};

export const getLaterProducts = () => async (dispatch, getStore) => {
  var currentStore = getStore();
  var products = currentStore.ProductReducer.allProducts.length
    ? [...currentStore.ProductReducer.allProducts]
    : await productActions.getAllProducts(dispatch, getStore);

  const { data } = await apiCall('/buylaterproducts/lite');

  var buyLaterProducts = [];
  data.forEach((item) => {
    var product = products.find((p) => p.id === item.id);
    if (product) {
      buyLaterProducts.push(product);
    }
  });

  dispatch({
    type: actionTypes.GET_LATER_PRODUCTS,
    payload: {
      products: buyLaterProducts,
      isLoaded: true,
    },
  });
};
export const removeFromLaterProducts = (item) => async (dispatch) => {
  try {
    let data = {
      ProductId: item.ProductId,
    };
    await apiCall('/buylaterproducts', data, null, HTTP.DELETE, null, true);

    dispatch({
      payload: item,
      type: actionTypes.REMOVE_FROM_LATER_PRODUCTS,
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};
export const addToFavorites =
  (item, favType = favoriteTypes.product) =>
  async (dispatch) => {
    try {
      let aType;
      let data;

      if (favType === favoriteTypes.product) {
        aType = actionTypes.ADD_TO_FAVORITES;
        data = {
          type: 1,
          id: item.id,
        };
      } else if (favType === favoriteTypes.brand) {
        aType = actionTypes.ADD_TO_FAVORITES_BRAND;
        data = {
          type: 2,
          id: item.id,
        };
      }

      await apiCall('/favorites', data, null, HTTP.PUT, null, true);

      dispatch({
        type: aType,
        payload: item,
      });

      return { success: true };
    } catch (e) {
      return { success: false, error: e };
    }
  };

export const removeFromFavorites =
  (item, favType = favoriteTypes.product) =>
  async (dispatch) => {
    try {
      let aType;
      let data;

      if (favType === favoriteTypes.product) {
        data = {
          type: 1,
          id: item.id,
        };
        aType = actionTypes.REMOVE_FROM_FAVORITES;
      } else if (favType === favoriteTypes.brand) {
        data = {
          type: 2,
          id: item.id,
        };
        aType = actionTypes.REMOVE_FROM_FAVORITES_BRAND;
      }

      await apiCall('/favorites', data, null, HTTP.DELETE, null, true);

      dispatch({
        type: aType,
        payload: item,
      });

      return { success: true };
    } catch (e) {
      return { success: false, error: e };
    }
  };

export const getCities =
  (cityId = null) =>
  async (dispatch) => {
    const { data } = settings.GET_DATA_FROM_AWS
      ? await bucketCall('/cities.json')
      : await apiCall('/cities');

    const cities = data.map((city) => ({
      value: city.id,
      label: city.name,
      towns: city.towns.map((town) => ({ value: town.id, label: town.name })),
    }));
    const city = cityId ? cities.find((_city) => _city.value === cityId) : null;
    const towns = city ? city.towns : null;

    dispatch({
      type: actionTypes.GET_CITIES,
      payload: {
        cities,
        city,
        towns,
      },
    });
  };

export const resetCityTown =
  (cityId = null) =>
  (dispatch, getState) => {
    const cities = getState().AccountReducer.cities;
    const city = cityId ? cities.find((_city) => _city.value === cityId) : null;
    const towns = city ? city.towns : null;

    dispatch({
      type: actionTypes.RESET_CITY_TOWN,
      payload: {
        cities,
        city,
        towns,
      },
    });
  };

export const getTowns = (cityId) => async (dispatch) => {
  const { data } = await apiCall(`/cities/${cityId}`);
  dispatch({
    type: actionTypes.GET_TOWNS,
    payload: data.towns,
  });
};

export const getTownByName = (cityName, townName) => async (dispatch) => {
  let result = await apiCall(
    '/cities/town',
    { cityName, townName },
    null,
    HTTP.POST,
    null,
    false,
  );

  dispatch({
    type: actionTypes.GET_TOWN_BY_NAME,
    payload: result.data,
  });
};

export const getSchools = (cityId) => async (dispatch) => {
  const { data } = await apiCall(`/cities/${cityId}/schools`);
  dispatch({
    type: actionTypes.GET_SCHOOLS,
    payload: data,
  });
};

export const changeTown = (cityId) => async (dispatch, getState) => {
  const city = getState().AccountReducer.cities.find(
    (_city) => _city.value === cityId,
  );

  dispatch({
    type: actionTypes.CHANGE_TOWN,
    payload: {
      city,
      towns: city.towns,
    },
  });
};

export const loginRequired =
  (required, path = null) =>
  (dispatch) => {
    if (path) {
      storage.setItem(keys.LastLocation, path);
    }
    dispatch({
      type: actionTypes.LOGIN_REQUIRED,
      payload: required,
    });
  };

export const updateMasterpassNumber =
  (number) => async (dispatch, getState) => {
    var currentState = getState();
    const user = currentState.AccountReducer.user;
    user.masterpassNumber = number;

    try {
      await apiCall(
        '/account/updatemasterpassnumber',
        user,
        null,
        HTTP.POST,
        null,
        true,
      );

      dispatch({
        type: actionTypes.UPDATE_MASTERPASS_NUMBER,
        payload: user,
      });

      return { success: true };
    } catch (error) {
      if (error.status === 400) {
        return { success: false, error: error.data };
      }

      return { success: false, error: error.response.data };
    }
  };

export const confirmEmail =
  ({ code, email }) =>
  async (dispatch) => {
    let data;
    try {
      var result = await apiCall(
        '/account/confirmmail',
        { Code: code, Email: email },
        null,
        HTTP.POST,
        null,
        true,
      );
      data = result.data;
      return {
        success: result.data.status,
        data: result.data,
        message: result.data.message,
      };
    } catch (error) {
      return { success: false, error: error.response.data };
    } finally {
      if (data.status) {
        await loginHelper(data, dispatch);
      }
    }
  };

export const activateEmail = (email, code) => async (dispatch) => {
  try {
    const req = {
      email,
      code,
    };
    var { data } = await apiCall(
      '/account/confirmmail',
      req,
      null,
      HTTP.POST,
      null,
      true,
    );
    if (data.status) {
      await loginHelper(data, dispatch);
    }

    return { success: data.status, error: data.message, user: data.user };
  } catch (error) {
    return { success: false, error: error.response.data };
  }
};

export const unlockUser = (token) => async () => {
  try {
    var result = await apiCall(
      '/account/unlockuser',
      null,
      { token },
      HTTP.GET,
      null,
      false,
    );
    return { success: result.data.status, message: result.data.message };
  } catch (error) {
    return { success: false, error: error.response.data };
  }
};

export const sendActivationMail = (email) => async () => {
  try {
    var data = await apiCall(
      '/account/resendactivationmail',
      { Email: email },
      null,
      HTTP.POST,
      null,
      true,
    );

    return { success: data.status, error: data.message, user: data.user };
  } catch (error) {
    return { success: false, error: error.response.data };
  }
};

export const setHandleModal = (modalName) => async (dispatch) => {
  dispatch({
    type: actionTypes.HANDLE_MODAL,
    payload: modalName,
  });
};
