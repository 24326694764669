import React, { Component } from 'react';
//import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';
import {
  customClassroomStyles,
  customClassroomStylesResponsive,
} from '../../helpers';
import * as actions from '../../routes/actions/Product';
import { customFilterOption } from './Utility';

const options = [{ value: '', label: 'Seçiniz...' }];

class Classroom extends Component {
  render() {
    const {
      name,
      onChange,
      valid,
      value,
      readOnly,
      placeholder,
      defaultItems,
    } = this.props;
    const items = defaultItems ? [...defaultItems] : [];
    for (let i = 3; i < 13; i++) {
      items.push({ value: i, label: `${i}. Sınıf` });
    }
    const selected = items.find((p) => p.value === value) || items[0];
    return (
      <Select
        id={name}
        isSearchable={false}
        placeholder={placeholder || 'Seçiniz...'}
        options={items}
        className={classNames(valid === false ? 'is-invalid' : 'valid')}
        styles={
          window.innerWidth > 568
            ? customClassroomStyles
            : customClassroomStylesResponsive
        }
        onChange={onChange}
        value={selected}
        filterOption={customFilterOption}
        isDisabled={readOnly}
      />
    );
  }
}

Classroom.propTypes = {};

const mapStateToProps = ({ ProductReducer }) => ({
  taxonomies: ProductReducer.taxonomies,
});

const mapDispatchToProps = {
  getTaxonomies: actions.getTaxonomies,
};

export default connect(mapStateToProps, mapDispatchToProps)(Classroom);
