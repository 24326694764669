import React, {
  useEffect,
  useState,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import apiCall from '../../services/api';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as contentActions from '../../routes/actions/Content';
import * as productActions from '../../routes/actions/Product';
import Icon from '../../components/Icon/Index';
import { replaceDotWithComma } from '../../helpers';
import Skeleton from '@material-ui/lab/Skeleton';
const MainBookDetailedSlider = forwardRef(
  (
    {
      url,
      addProductToBasket,
      basketKey,
      basket,
      eventType,
      productId,
      user,
      mode,
      title,
      onChange,
      taxonomies,
      ...props
    },
    ref,
  ) => {
    // const [bookList, setBookList] = useState([]);
    const [page, setPage] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      getDatas();
    }, [basket]);

    useImperativeHandle(ref, () => ({
      newDataFromParent(newData) {
        setPage(newData);
      },
    }));

    useEffect(() => {
      setLoading(true);
      if (props.sliderName) {
        apiCall(`/contents/content/${props.sliderName}/1`)
          .then((res) => {
            setPage(res.data);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }, [props.sliderName]);

    const productList = useMemo(() => {
      let output = [];
      for (const id of page?.content?.products ?? []) {
        const item = props.products.find((m) => m.id === id);
        if (item) {
          output.push(item);
        }
      }
      return output;
    }, [page, props.products]);

    const GA4Products = useMemo(() => {
      let products = [];
      productList.forEach((product, index) => {
        const taxonIds = new Set(
          product.taxons.filter((element) => typeof element === 'number'),
        );

        const productTaxons = taxonomies
          .filter((t) => t.taxons.some((tx) => taxonIds.has(tx.id)))
          .flatMap((t) => {
            return t.taxons.filter((taxon) => taxonIds.has(taxon.id));
          });
        const productTopTaxonId = productTaxons?.[0]?.taxonomyId;
        const productTopTaxon = taxonomies?.filter(
          (t) => t.id == productTopTaxonId,
        );
        products.push({
          item_id: product.id,
          item_name: JSON.stringify(product.name),
          currency: 'TRY',
          index: index,
          item_category: JSON.stringify(productTaxons?.[0]?.name),
          item_category2: JSON.stringify(productTaxons?.[1]?.name),
          item_category3: JSON.stringify(productTaxons?.[2]?.name),
          item_category4: JSON.stringify(productTaxons?.[3]?.name),
          item_category5: JSON.stringify(productTaxons?.[4]?.name),
          item_variant: JSON.stringify(
            productTaxons?.[0]?.name ?? product.category,
          ),
          price: Number(
            productActions.calculateProductPrice(product).toFixed(2),
          ),
          discount: 0,
          item_list_id: productTopTaxon?.[0]?.id,
          item_list_name: productTopTaxon?.[0]?.description,
        });
      });

      return products;
    }, [page, props.products]);

    function getDatas() {
      // if (url) {
      //   apiCall(url).then((res) => {
      //     setBookList(
      //       res.data.map((m) => ({
      //         id: m.id,
      //         name: m.name,
      //         image: m.imageUrl,
      //         price: m.price,
      //       }))
      //     );
      //   });
      // } else
      if (eventType) {
        const clientId = user ? user.id : '';
        const basket_ = basket.map((item) => ({
          uid: item.product.id,
          sepetfiyati: item.total,
          adet: item.quantity,
        }));
        let model = {
          eventtipi: eventType,
          satiskanali: 1,
          urun: {
            uid: productId,
          },
          sepeturunleri: basket_,
          musteri: {
            mid: clientId,
          },
        };
        props.getRecommendationProducts(model);
      }
    }

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: productList.length < 5 ? productList.length : 5,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: productList.length < 4 ? productList.length : 4,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: productList.length < 3 ? productList.length : 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            infinite: true,
          },
        },
      ],
    };

    const handleClick = (event) => {
      if (onChange) {
        onChange(event.target.name, props.sliderName, true);
      }
    };

    const toggleShowSlider = async (active) => {
      const newState = {
        ...page,
        active,
      };
      setPage(newState);
      await props.savePage(newState.id, newState);
      props.publishPage(newState.id);
    };

    if (mode !== 'draft' && page.active === 0) {
      return;
    }

    return (
      <>
        {productList.length && GA4Products.length ? (
          <Helmet>
            <script>
              {`
                dataLayer.push({ecommerce: null });
                dataLayer.push({
                  event: "view_item_list",
                    'ecommerce': {
                      'items': ${JSON.stringify(GA4Products)}
                    }});
            `}
            </script>
          </Helmet>
        ) : null}
        {loading ? (
          <div className="main-book-slider">
            <Skeleton variant="rect" width={202} height={284} />
          </div>
        ) : (
          <div className="main-book-slider main-book-slider-detailed">
            <div className="d-flex align-items-center">
              <h2>{page?.content?.title ? page?.content?.title : title}</h2>
              {/* <div className="more-arrow" /> */}
            </div>
            {mode === 'draft' && (
              <div id='editable-container-5' key={'editable-container-5'} className="editable-container">
                <button
                  className="btn"
                  name="editableSlider"
                  onClick={handleClick}
                >
                  <Icon icon="iEditable" />
                </button>
                {page?.active === 1 && (
                  <button className="btn" onClick={() => toggleShowSlider(0)}>
                    <Icon icon="iEyeOff" />
                  </button>
                )}
                {page?.active === 0 && (
                  <button className="btn" onClick={() => toggleShowSlider(1)}>
                    <Icon icon="iEyeOn" />
                  </button>
                )}
              </div>
            )}
            <Slider {...settings}>
              {page?.content?.sliderImage && (
                <div className="book-detail-container">
                  <div className="book-detail-box border-0">
                    <img
                      //lazyLoad
                      loading="lazy"
                      className="img-fluid head-image"
                      src={page?.content?.sliderImage}
                      alt=""
                    />
                  </div>
                </div>
              )}
              {productList.map((book) => (
                <Link
                  to={`/urunler/detay/${book.id}`}
                  key={book.id}
                  className="book-detail-container"
                  title={book.name}
                >
                  <div className="book-detail-box">
                    <img
                      className="img-fluid"
                      src={book.image || book.imageUrl}
                      alt={book.name}
                      loading="lazy"
                    />
                    <div className="book-detail-body">
                      <div>{book.name}</div>
                      {book.discount !== 0 && (
                        <div className="slide-price-old">
                          {replaceDotWithComma(
                            (
                              book.total / (1 - book.discount / 100) || 0
                            ).toFixed(2),
                          )}{' '}
                          ₺
                        </div>
                      )}
                      <div>
                        <span>
                          {replaceDotWithComma(book.total.toFixed(2))} ₺
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </Slider>
          </div>
        )}
      </>
    );
  },
);

const mapStateToProps = ({ ProductReducer, AccountReducer }) => ({
  basketKey: ProductReducer.basketKey,
  basket: ProductReducer.basket,
  user: AccountReducer.user,
  // relatedProducts: ProductReducer.relatedProducts,
  products: ProductReducer.products,
});
const mapDispatchToProps = {
  // getRelatedProducts: productActions.getRelatedProducts,
  publishPage: contentActions.publishPage,
  savePage: contentActions.savePage,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(MainBookDetailedSlider);
