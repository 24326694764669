import {
    HTTP,
    defaultValues,
    keys,
    settings,
    S3BucketEndPoint,
  } from '../../../services/constants';
  import apiCall, { tatsApiCall, bucketCall } from '../../../services/api';
  import storage from '../../../services/storage';
  
  export const getInstallments = async (total) => {
    const { data } = await apiCall(
      '/payment/installments',
      null,
      {Total: total},
      HTTP.POST,
      null,
      true,
    );
    return data;
  };
   