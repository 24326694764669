import React from 'react';
import { hydrate, render } from 'react-dom';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import { googleAnalytics } from './services/constants';
import 'react-vis/dist/style.css';
import './index.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/bootstrap/store.scss';
import TagManager from 'react-gtm-module';

ReactGA.initialize(googleAnalytics.trackingId);

const tagManagerArgs = {
  gtmId: 'GTM-KT5GVRN',
};

TagManager.initialize(tagManagerArgs);

const release = process.env.REACT_APP_SENTRY_RELEASE;
if (release) {
  Sentry.init({
    environment: getEnv(),
    release: release,
    dsn: 'https://727d24ee884e4854b10cd0e262a86bd2@sentry.io/1865453',
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'Error: ResizeObserver loop limit exceeded',
      '{"isTrusted":false}',
      'Error: {"isTrusted":false}'
    ],
    integrations: [
      new Sentry.Integrations.GlobalHandlers({
        onerror: true,
        onunhandledrejection: false,
      }),
    ],
    beforeSend(event) {
      if (event.exception?.values[0]?.mechanism?.handled === true) {
        return null;
      }
      return event;
    },
  });
}
window.onload = () => {
  const rootElement = document.getElementById('root');
  if (rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
  } else {
    render(<App />, rootElement);
  }
};

function getEnv() {
  var env = process.env.REACT_APP_NODE_ENV.toLowerCase();
  var newEnv = env.charAt(0).toUpperCase() + env.slice(1);
  return newEnv;
}

// ReactDOM.render(<App/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
