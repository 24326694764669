import React, { useEffect } from 'react';

const Sitemap = () => {
  const apiUrl = {
    Test: 'https://api.test.tongucmagaza.com/sitemap/sitemap.xml',
    Production: 'https://api.tongucmagaza.com/sitemap/sitemap.xml',
  };
  const endPoint = apiUrl[process.env.REACT_APP_NODE_ENV] || apiUrl.Test;
    useEffect(() => {
        const fetchSitemap = async () => {
            try {
              const response = await fetch(endPoint, {
                  headers: {
                    'Accept': 'application/xml',
                  },
                });
              const text = await response.text();
                document.head.innerHTML = `
                    <meta name="robots" content="noindex, follow" />
                    <title>Sitemap</title>
                `;
                const blob = new Blob([text], { type: 'application/xml' });
                const url = window.URL.createObjectURL(blob);
                window.location.replace(url);
            } catch (error) {
                console.error('Sitemap verisi alınırken hata oluştu:', error);
            }
        };
        fetchSitemap();
    }, []);

    return <div>Sitemap yükleniyor...</div>;
};

export default Sitemap;
