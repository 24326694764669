import React, { Component } from 'react';
import { connect } from 'react-redux';
import _remove from 'lodash/remove';
import { Helmet } from 'react-helmet';
import 'react-popper-tooltip/dist/styles.css';
import {
  // EditableItem,
  EditableToolbar,
  EditableSlider,
  FormModal,
  GalleryModal,
  IconButton,
} from '../../components';
// import {
//   faAngleDown,
//   faAngleUp,
//   faPlusCircle,
//   faTrash,
//   faPencilAlt,
// } from "@fortawesome/free-solid-svg-icons";
import { faImages } from '@fortawesome/free-regular-svg-icons';

import * as actions from '../../routes/actions/Content';
import * as productActions from '../../routes/actions/Product';
import * as modeActions from '../../routes/actions/Mode';

import MainSlider from './MainSlider';
import MainTopSlider from './MainTopSlider';
import MainBookSlider from './MainBookSlider';
import MainBookDetailedSlider from './MainBookDetailedSlider';

import { move } from '../../helpers';
// import FormSchemas from "../../variables/FormSchemas";
import { toast } from 'react-toastify';
// import { PlaceHolder735x250, PlaceHolder359x250 } from "../../assets/images";
import MainBanner from '../../layout/MainBanner';
import MainBannerFooter from '../../layout/MainBannerFooter';
// import BookRecommendation from "./BookRecommendation/BookRecommendation";
import MainHelperLinks from './MainHelperLinks';
import MainLibrary from './MainLibrary';
import HeadBanner from '../../layout/HeadBanner';
import EditableBanner from '../../components/Modal/EditableBanner';
import EditableStory from '../../components/Modal/EditableStory';
import MultiCard from './MultiCard';
import CardBanner from './CardBanner';
import Skeleton from '@material-ui/lab/Skeleton';

// const menuItems = [
//   {
//     name: "add",
//     icon: faPlusCircle,
//   },
//   {
//     name: "edit",
//     icon: faPencilAlt,
//   },
//   {
//     name: "moveup",
//     icon: faAngleUp,
//   },
//   {
//     name: "movedown",
//     icon: faAngleDown,
//   },
//   {
//     name: "remove",
//     icon: faTrash,
//     className: "text-danger",
//   },
// ];

const toolbarItems = [
  {
    name: 'gallery',
    title: 'Galeriyi Düzenle',
    icon: faImages,
  },
];
const windowWidth = window.screen.width;
class Main extends Component {
  state = {
    mode: 'live',
    show: false,
    showGallery: false,
    formData: {},
    itemType: '',
    index: -1,
    modified: false,
    schema: '',
    uiSchema: '',
    showEditableSlider: false,
    editableSliderName: '',
    showEditableBanner: false,
    editableBannerName: '',
    showEditableStory: false,
    editableStoryName: '',
    imageOnSlider: false,
    bannerType: '',
  };

  constructor(props) {
    super(props);
  
    // Dinamik referans nesnesi
    this.references = {
      anasayfaSlider1: React.createRef(),
      anasayfaSlider2: React.createRef(),
      anasayfaSlider3: React.createRef(),
      anasayfaSlider4: React.createRef(),
      anasayfaSlider5: React.createRef(),
      anasayfaBanner1: React.createRef(),
      anasayfaBanner2: React.createRef(),
      anasayfaBanner3: React.createRef(),
      anasayfaStory1: React.createRef(),
      anasayfaCardBanner1: React.createRef(),
      anasayfaCardBanner2: React.createRef(),
      anasayfaCardBanner3: React.createRef(),
      anasayfaCardBanner4: React.createRef(),
    };
  }

  componentDidMount() {
    this.props.getMainPage('anasayfa', 1);
    this.getProducts();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.getMainPage('anasayfa', 1);
      this.getProducts();
    }
  }

  getProducts = () => {
    // this.props.getRelatedProducts("", "", "", "");
    this.props.getProductsById('', '', '');
  };

  change = (page) => {
    this.setState({ modified: true });
    this.props.changeMainPage(page);
  };

  handleItemClick = (command, item, itemType, index, schema, uiSchema) => {
    const { mainPage } = this.props;
    const collection =
      itemType === 'link' ? mainPage.content.links : mainPage.content.items;

    switch (command.name) {
      case 'remove':
        const confirmed = window.confirm(
          'Görsel bileşen silinecektir, emin misiniz?',
        );
        if (confirmed) {
          _remove(collection, (p) => p === item);
        }
        break;
      case 'moveup':
        move(collection, index, index - 1);
        break;
      case 'movedown':
        move(collection, index, index + 1);
        break;
      case 'add':
        collection.splice(index + 1, 0, { id: collection.length + 1 });
        break;
      case 'edit':
        this.setState({
          show: true,
          formData: item,
          itemType,
          index,
          schema,
          uiSchema,
        });
        return;
      default:
        break;
    }

    this.change(mainPage);
  };

  handleSubmit = (formData) => {
    const { mainPage } = this.props;
    const { itemType, index } = this.state;
    const collection =
      itemType === 'link' ? mainPage.content.links : mainPage.content.items;
    collection[index] = formData;
    this.change(mainPage);
    this.setState({ show: false, formData: {}, itemType: '', index: -1 });
  };

  handleChangeMode = async (mode, sliderName, imageOnSlider, value) => {
    const { getMainPage, mainPage, publishPage } = this.props;
    this.props.setMode(mode);
    switch (mode) {
      case 'live':
        const { success } = await this.props.savePage(mainPage.id, mainPage);
        if (success) {
          toast.success('Kayıt edildi.');
          this.setState({ modified: false }, () => getMainPage('anasayfa', 0));
        }
        break;
      case 'gallery':
        this.setState({ showGallery: true });
        break;
      case 'addadv':
        mainPage.content.items.push({ id: mainPage.content.items.length + 1 });
        this.change(mainPage);
        break;
      case 'addlink':
        mainPage.content.links.push({ id: mainPage.content.links.length + 1 });
        this.change(mainPage);
        break;
      case 'cancel':
        this.setState({ mode: 'live' }, () => getMainPage('anasayfa', 1));
        break;
      case 'draft':
        this.setState({ mode }, () => getMainPage('anasayfa', 0));
        break;
      case 'publish':
        const result = await publishPage(mainPage.id);
        if (result.success) {
          toast.success('Son yapılan değişikler yayına alındı.');
          getMainPage('anasayfa', 1);
        }
        break;
      case 'editableSlider':
        this.setState({
          showEditableSlider: true,
          editableSliderName: sliderName,
          imageOnSlider: imageOnSlider,
        });
        break;
      case 'editableBanner':
        this.setState({
          showEditableBanner: true,
          editableBannerName: sliderName,
          bannerType: value,
        });
        break;
      case 'editableStory':
        this.setState({
          showEditableStory: true,
          editableStoryName: sliderName,
        });
        break;
      default:
        break;
    }
  };

  editableSliderPublish = (changedData) => {
    if (this[this.state.editableSliderName]?.current) {
      this[this.state.editableSliderName].current.newDataFromParent(
        changedData,
      );
    }
  };

  editableBannerPublish = (changedData) => {
    if (this[this.state.editableBannerName]?.current) {
      this[this.state.editableBannerName].current.newDataFromParent(
        changedData,
      );
    }
  };

  editableStoryPublish = (changedData) => {
    if (this[this.state.editableStoryName]?.current) {
      this[this.state.editableStoryName].current.newDataFromParent(changedData);
    }
  };

  render() {
    const { mainPage, user } = this.props;
    const current = mainPage.content || { items: [], links: [], slides: [] };
    const editable = user.editor === true && !user.brandId;

    return (
      <>
        <Helmet>
          <title>Tonguç'la çalış, Başarıya alış!</title>
          <link rel="canonical" href="https://tongucmagaza.com/" />
          <script>
            {`gtag('event', 'page_view', {
              'send_to':'AW-596855146',
              'ecomm_pagetype': 'home'
            });`}
          </script>
        </Helmet>
        <div className="container">
          <div className="row">
            {editable && (
              <div className="col-12">
                <EditableToolbar
                  editSlider
                  mode={this.state.mode}
                  modified={this.state.modified}
                  onChange={this.handleChangeMode}
                  published={mainPage.published}
                  publishVisible={true}
                  saveVisible={true}
                >
                  <IconButton
                    className="btn-primary mx-2 text-capitalize"
                    // icon={item.icon}
                    onClick={() =>
                      this.handleChangeMode('editableStory', 'anasayfaStory1')
                    }
                  >
                    Hikayeyi Düzenle
                  </IconButton>
                  {toolbarItems.map((item, i) => (
                    <IconButton
                      className="btn-primary mx-2 text-capitalize"
                      icon={item.icon}
                      onClick={() => this.handleChangeMode(item.name)}
                      key={i}
                    >
                      {item.title}
                    </IconButton>
                  ))}
                </EditableToolbar>
              </div>
            )}
            <div className="col-12  ">
              <div className="d-flex flex-column">
                <MainTopSlider
                  storyName={'anasayfaStory1'}
                  ref={this.references.anasayfaStory1}
                  mode={this.state.mode}
                  onChange={this.handleChangeMode}
                  key="anasayfaStory1Key"
                />
              </div>
            </div>
            <div className="col-12  ">
              <div className="d-flex flex-column">
                {current.slides.length === 0 ? (
                  <Skeleton
                    variant="rect"
                    className="w-100"
                    width={1200}
                    height={280}
                  />
                ) : (
                  <MainSlider
                    key={current.slides.length}
                    slides={current.slides}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <HeadBanner
                mobile
                onChange={this.handleChangeMode}
                ref={this.references.anasayfaBanner2}
                bannerName={'anasayfaBanner2'}
                mode={this.state.mode}
                headBanner
                key="anasayfaBanner2Key"
              />
            </div>
          </div>
        </div>
        <div className="main-book-slider-container">
          <div className="container">
            <div className="row">
              <div className="col-12  ">
                  <MainBookSlider
                  ref={this.references.anasayfaSlider1}
                  sliderName={'anasayfaSlider1'}
                  mode={this.state.mode}
                  onChange={this.handleChangeMode}
                  key="anasayfaSlider1Key"
                />

                <MainBookDetailedSlider
                  ref={this.references.anasayfaSlider2}
                  sliderName={'anasayfaSlider2'}
                  mode={this.state.mode}
                  onChange={this.handleChangeMode}
                  taxonomies={this.props.taxonomies}
                  key="anasayfaSlider2Key"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12  ">
                <MainBanner
                  ref={this.references.anasayfaBanner1}
                  bannerName={'anasayfaBanner1'}
                  mode={this.state.mode}
                  onChange={this.handleChangeMode}
                  bannerType={this.state.bannerType}
                  key="anasayfaBanner1Key"
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12">
                <MainBanner
                  mobile
                  ref={this.anasayfaBanner1}
                  bannerName={"anasayfaBanner1"}
                  mode={this.state.mode}
                  onChange={this.handleChangeMode}
                />
              </div>
            </div> */}

            <div className="row">
              <div className="col-12  ">
                <div className="row">
                  <MultiCard
                    user={this.props.user}
                    taxonomies={this.props.taxonomies}
                  />
                  <CardBanner
                    ref={this.references.anasayfaCardBanner3}
                    bannerName={'anasayfaCardBanner3'}
                    mode={this.state.mode}
                    onChange={this.handleChangeMode}
                    key="anasayfaCardBanner3Key"
                  />
                  <CardBanner
                    ref={this.references.anasayfaCardBanner4}
                    bannerName={'anasayfaCardBanner4'}
                    mode={this.state.mode}
                    onChange={this.handleChangeMode}
                    key="anasayfaCardBanner4Key"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12  ">
                <MainBookDetailedSlider
                  ref={this.references.anasayfaSlider3}
                  sliderName={'anasayfaSlider3'}
                  mode={this.state.mode}
                  onChange={this.handleChangeMode}
                  taxonomies={this.props.taxonomies}
                  key="anasayfaSlider3Key"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12  ">
                <MainBookSlider
                  title={'Tam Sana Göre'}
                  eventType={3}
                  productId={0}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12  ">
                <MainBookSlider
                  ref={this.references.anasayfaSlider4}
                  sliderName={'anasayfaSlider4'}
                  mode={this.state.mode}
                  onChange={this.handleChangeMode}
                  key="anasayfaSlider4Key"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12  ">
                <MainBookDetailedSlider
                  ref={this.references.anasayfaSlider5}
                  sliderName={'anasayfaSlider5'}
                  mode={this.state.mode}
                  onChange={this.handleChangeMode}
                  taxonomies={this.props.taxonomies}
                  key="anasayfaSlider5Key"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12  ">
                <div className="row">
                  <MultiCard
                    user={this.props.user}
                    taxonomies={this.props.taxonomies}
                  />
                  <CardBanner
                    ref={this.references.anasayfaCardBanner1}
                    bannerName={'anasayfaCardBanner1'}
                    mode={this.state.mode}
                    onChange={this.handleChangeMode}
                    key="anasayfaCardBanner1Key"
                  />
                  <CardBanner
                    ref={this.references.anasayfaCardBanner2}
                    bannerName={'anasayfaCardBanner2'}
                    mode={this.state.mode}
                    onChange={this.handleChangeMode}
                    key="anasayfaCardBanner2Key"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <BookRecommendation /> */}

        <div className="main-helper-links-container">
          <div className="container">
            <div className="row">
              <div className="col-12  ">
                <MainHelperLinks />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12  ">
              <MainLibrary />
            </div>
          </div>
        </div>
        {(!user.id || user.showBanner || this.props.user.editor) && (
          <div className="container">
            {
              /* <div className="row">
            <div className="col-12  ">
              <a href="https://store.tongucakademi.com/sayfa/kitap-aktivasyonu-nasil-yapilir/10796">
              <img className="img-fluid w-100" sizes="{`(max-width: 768px) 768w, 100%`}"
                src={`${windowWidth > 768 ? "https://content.test.tongucakademi.com/Uploads/v2/public/other/magazabannerdesktop.png" : "https://content.test.tongucakademi.com/Uploads/v2/public/other/magazabannermobile.png"}`}
              alt="" />
              </a>

            </div>
          </div> */
              <div className="row">
                <div className="col-12  ">
                  <MainBannerFooter
                    ref={this.references.anasayfaBanner3}
                    bannerName={'anasayfaBanner3'}
                    mode={this.state.mode}
                    onChange={this.handleChangeMode}
                    bannerType={this.state.bannerType}
                    key="anasayfaBanner3Key"
                  />
                </div>
              </div>
            }
          </div>
        )}

        <FormModal
          show={this.state.show}
          onClose={() => this.setState({ show: false })}
          onSubmit={this.handleSubmit}
          schema={this.state.schema}
          uiSchema={this.state.uiSchema}
          formData={this.state.formData}
        />
        <GalleryModal
          show={this.state.showGallery}
          onClose={() => this.setState({ showGallery: false })}
          items={current.slides}
          page={mainPage}
          onChange={this.change}
        />
        <EditableSlider
          show={this.state.showEditableSlider}
          editableSliderName={this.state.editableSliderName}
          onClose={() => this.setState({ showEditableSlider: false })}
          onPublish={this.editableSliderPublish}
          imageOnSlider={this.state.imageOnSlider}
        />
        <EditableBanner
          show={this.state.showEditableBanner}
          editableBannerName={this.state.editableBannerName}
          onClose={() => this.setState({ showEditableBanner: false })}
          onPublish={this.editableBannerPublish}
          bannerType={this.state.bannerType}
        />
        <EditableStory
          show={this.state.showEditableStory}
          editableStoryName={this.state.editableStoryName}
          onClose={() => this.setState({ showEditableStory: false })}
          onPublish={this.editableStoryPublish}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  AccountReducer,
  ContentReducer,
  ProductReducer,
  ModeReducer,
}) => ({
  user: AccountReducer.user,
  mainPage: ContentReducer.mainPage,
  // relatedProducts: ProductReducer.relatedProducts,
  mode: ModeReducer.mode,
  products: ProductReducer.products,
  taxonomies: ProductReducer.taxonomies,
});

const mapDispatchToProps = {
  getMainPage: actions.getMainPage,
  changeMainPage: actions.changeMainPage,
  savePage: actions.savePage,
  publishPage: actions.publishPage,
  // getRelatedProducts: productActions.getRelatedProducts,
  setMode: modeActions.setMode,
  getProductsById: productActions.getProductsById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
