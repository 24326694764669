import Pages from '../views/Account/Pages';
import Wallet from '../views/Account/Wallet';
//import CoinShow from "../views/Product/CoinShow"
import {
  Orders,
  Addresses,
  Favorites,
  //NotFound,
  Main,
  Page,
  BrandList,
  BrandShow,
  BrandBoard,
  BlogList,
  ExamMode,
  ProductShow,
  CoinShow,
  Search,
  Products,
  Reservation,
  PaymentView,
  PaymentSuccess,
  ShoppingCart,
  AccountIndex,
  About,
  Contract,
  PoliciesIndex,
  Stores,
  Support,
  Desk,
  Profile,
  ProfileLesson,
  Chapters,
  Books,
  Lessons,
  Quiz,
  Answers,
  Summary,
  Play,
  Scheduler,
  Stats,
  StatsLesson,
  Notifications,
  Verification,
  SearchResult,
  PdfViewer,
  Sitemap
} from '../views';
import Order from '../views/Account/Order';
import Reservations from '../views/Account/Reservations';
import Settings from '../views/Account/Settings';
import Preferences from '../views/Account/Preferences';
import ChangePassword from '../views/Account/ChangePassword';
import Privacy from '../views/Policies/Privacy';
import Cookie from '../views/Policies/Cookie';
import Compliance from '../views/Policies/Compliance';
import Refund from '../views/Policies/Refund';
import SaleContract from '../views/Policies/SaleContract';
import MembershipAgreement from '../views/Policies/MembershipAgreement';
import {
  StoreLayout,
  DeskLayout,
  FrameLayout,
  MessageLayout,
  BlogLayout,
} from '../layout';
import ResetPassword from '../views/Password/ResetPassword';
import ErrorLayout from '../layout/ErrorLayout';
import EmailVerification from '../views/Verification/Email';
import UnlockUser from '../views/Account/UnlockUser';

const routes = [
  {
    path: '/',
    component: StoreLayout,
    routes: [
      { path: '/blog', component: BlogList },
      {
        path: '/',
        component: Main,
      },
      {
        path: '/sayfa/:code/:id',
        component: Page,
      },
      // {
      //   path: "/hesapaktivasyonu",
      //   component: EmailVerification
      // },
      // {
      //   path: "/hesapaktivasyonu/:code",
      //   component: EmailVerification
      // },
      {
        path: '/sitemap/sitemap.xml',
        component: Sitemap,
      },
      {
        path: '/kilitacma',
        component: UnlockUser,
      },
      {
        path: '/markalar',
        component: BrandList,
      },
      {
        path: '/markalar/detay/:code',
        component: BrandShow,
      },

      {
        path: '/markalar/duvar-yazilari',
        component: BrandBoard,
      },

      {
        path: '/markalar/page',
        component: Page,
      },
      {
        path: '/urunler/rezervasyon',
        exact: true,
        component: Reservation,
      },
      {
        path: '/urunler/detay/:productId',
        exact: true,
        component: ProductShow,
      },
      {
        path: '/jeton/detay/:coinId',
        hidden: true,
        component: CoinShow,
      },
      {
        path: '/urunler/arama',
        exact: true,
        component: Search,
      },
      {
        path: '/urunler/:category?',
        component: Products,
      },
      {
        path: '/odeme/:type/:id',
        component: PaymentView,
      },
      {
        path: '/odeme/:type/tamamlandi/:orderNumber',
        component: PaymentSuccess,
      },
      {
        path: '/sepetim',
        component: ShoppingCart,
      },
      {
        path: '/biz-kimiz',
        component: About,
      },
      {
        path: '/iletisim',
        component: Contract,
      },
      {
        path: '/satis-noktalarimiz',
        component: Stores,
      },
      {
        path: '/bildirimler',
        component: Notifications,
      },
      {
        path: '/mail-dogrulama',
        component: Verification,
      },
      {
        path: '/reset-password',
        component: ResetPassword,
      },
      {
        path: '/sozlesmeler',
        component: PoliciesIndex,
        routes: [
          {
            path: '/sozlesmeler/gizlilik-politikasi',
            title: 'Gizlilik Politikası',
            component: Privacy,
            icon: 'chevronRight',
          },
          {
            path: '/sozlesmeler/cerez-politikasi',
            title: 'Çerez Politikası',
            component: Cookie,
            icon: 'chevronRight',
          },
          {
            path: '/sozlesmeler/cayma-hakki-ve-iade-kosullari',
            title: 'Cayma Hakkı ve İade Koşulları',
            component: Refund,
            icon: 'chevronRight',
          },
          {
            path: '/sozlesmeler/mesafeli-satis-sozlesmesi',
            title: 'Mesafeli Satış Sözleşmesi',
            component: SaleContract,
            icon: 'chevronRight',
          },
          {
            path: '/sozlesmeler/uyelik-sozlesmesi',
            title: 'Üyelik Sözleşmesi',
            component: MembershipAgreement,
            icon: 'chevronRight',
          },
          {
            path: '/sozlesmeler/aydinlatma-metni',
            title: 'Aydınlatma Metni',
            component: Compliance,
            icon: 'chevronRight',
          },
          {
            path: '/sozlesmeler/riza-metni',
            title: 'Rıza Metni',
            component: Compliance,
            icon: 'chevronRight',
          },
        ],
      },
      {
        path: '/hesabim',
        title: 'Hesabım',
        component: AccountIndex,
        routes: [
          {
            path: '/hesabim/siparislerim',
            title: 'Siparişlerim',
            component: Orders,
            icon: 'iShipping',
          },
          {
            path: '/hesabim/siparislerim/:id',
            title: 'Sipariş Detay',
            exact: true,
            component: Order,
            hidden: true,
            icon: 'iShipping',
          },
          {
            path: '/hesabim/sari-sayfalar',
            title: 'Sayfalarım',
            component: Pages,
            icon: 'iDoc',
            roles: ['editor'],
          },
          {
            path: '/hesabim/jetonlarim',
            title: 'Jetonlarım',
            component: Wallet,
            icon: 'iWallet',
          },
          // {
          //   path: "/hesabim/rezervasyonlarim",
          //   title: "Rezervasyonlarım",
          //   component: Reservations,
          //   icon: "iReservation"
          // },
          {
            path: '/hesabim/hesap-bilgilerim',
            title: 'Hesap Bilgilerim',
            component: Settings,
            icon: 'iAccount',
          },
          {
            path: '/hesabim/adres-bilgilerim',
            title: 'Adres Bilgilerim',
            component: Addresses,
            icon: 'iLocation',
          },
          {
            path: '/hesabim/duyuru-tercihlerim',
            title: 'Duyuru Tercihlerim',
            component: Preferences,
            icon: 'iPreferences',
          },
          {
            path: '/hesabim/favorilerim',
            title: 'Listelerim ',
            component: Favorites,
            icon: 'iFavorites',
          },
          {
            path: '/hesabim/sifre-degistir',
            title: 'Şifre Değiştir',
            component: ChangePassword,
            icon: 'iPassword',
          },
          {
            path: '/hesabim/yardim',
            title: 'Yardım',
            component: Support,
            icon: 'iQuestion',
          },
        ],
      },
    ],
  },
  {
    path: '/dijitalkitap',
    component: DeskLayout,
    routes: [
      { path: '/dijitalkitap', component: Desk },
      { path: '/dijitalkitap/searchresult', component: SearchResult },
      { path: '/dijitalkitap/profile', component: Profile },
      {
        path: '/dijitalkitap/profile/lesson/:bransKod',
        component: ProfileLesson,
      },
      { path: '/dijitalkitap/profile/chapters/:bransKod', component: Chapters },
      { path: '/dijitalkitap/book/lessons/:id', component: Lessons },
      { path: '/dijitalkitap/book/lessons/:id/read', component: PdfViewer },
      { path: '/dijitalkitap/books', component: Books },
      { path: '/dijitalkitap/books/:groupId', component: Books },
      { path: '/dijitalkitap/book/lesson/subject/quiz/:id', component: Quiz },
      {
        path: '/dijitalkitap/book/lesson/subject/quiz/answers/:testId',
        component: Answers,
      },
      {
        path: '/dijitalkitap/book/lesson/subject/quiz/summary/:testId',
        component: Summary,
      },
      {
        path: '/dijitalkitap/book/lesson/subject/quiz/play/:testId',
        component: Play,
      },
      { path: '/dijitalkitap/programci', component: Scheduler },
      { path: '/dijitalkitap/sinav-modu/:examCode', component: ExamMode },
      { path: '/dijitalkitap/sinav-modu', component: ExamMode },
      { path: '/dijitalkitap/analizci', component: Stats },
      { path: '/dijitalkitap/analizci/ders/:bransId', component: StatsLesson },
    ],
  },
  {
    path: '/pay3d',
    component: FrameLayout,
  },
  {
    path: '/bakim',
    component: MessageLayout,
  },
  {
    path: '/blog',
    component: BlogLayout,
    routes: [
      { path: '/blog', component: BlogList },
      { path: '/blog/:id', component: BlogList },
      {
        path: '/blog/blog-yazilari/sari-sayfalar',
        title: 'Sayfalarım',
        component: Pages,
        icon: 'iDoc',
      },
      {
        path: '/blog/blog-yazilari/yardim',
        title: 'Yardım',
        component: Support,
        icon: 'iQuestion',
      },
    ],
  },
  {
    path: '/bakim-modu',
    component: ErrorLayout,
  },
];

export { routes };
