import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as actions from '../../routes/actions/Study';
import * as accountActions from '../../routes/actions/Account';
import {
  Breadcrumb,
  LinkButton,
  // InformationModal,
  SuccessModal,
} from '../../components';
import Book from './Book';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import Select from 'react-select';
import { customStyles } from '../../helpers/index';
import { Link } from 'react-router-dom';
import { ScoreBadge, InformationModal } from '../../components';
import { ProgressBar } from '../../components';
import classNames from 'classnames';
import { calc } from '../../helpers/mathExtensions';
import Icon from '../../components/Icon/Index';

const GroupBook = (props, { className }) => (
  <div className={classNames('card card-book', className)}>
    {props.id ? (
      <Link to={`/dijitalkitap/books/${props.digitalGroupId}`}>
        <div className="text-center img-wrapper">
          <img src={props.imageUrl} className="img-fluid" alt={props.name} />
          <img
            src={require('../../assets/images/new.png')}
            alt=""
            className="ekDokuman"
            style={{
              visibility: props.dosyaVarmi ? 'visible' : 'hidden',
            }}
            //width="125"
            //height="125"
          />
        </div>
        <div className="card-book-operations">
          <div className="title" title={props.name}>
            {props.bookName || props.name}
          </div>
          <div className="mt-2">
            {props.progress > 0 ? (
              <ProgressBar value={props.progress} />
            ) : (
              <LinkButton
                to={`/dijitalkitap/books/${props.digitalGroupId}`}
                className="btn-primary w-100 border-radius-4 fs-12 font-weight-500"
              >
                İncele
              </LinkButton>
            )}
          </div>
        </div>
      </Link>
    ) : null}
  </div>
);

class BookDark extends React.PureComponent {
  constructor(props) {
    super(props);
    this.key = React.createRef();
  }
  activate = (e) => {
    e.preventDefault();
    this.props.onClick(this.key.current.value);
  };

  render() {
    const productPrice = this.props
      ? ((this.props.total * (100 + this.props.tax)) / 100 || 0).toFixed(2)
      : 0;
    const { activityType } = this.props;

    return (
      <>
        <div className="card card-book not-activated">
          <div className="text-center img-wrapper position-relative product-image">
            <img
              src={this.props.imageUrl}
              className="img-fluid"
              alt={this.props.name}
            />
            <img
              src={require('../../assets/images/new.png')}
              alt=""
              className="ekDokuman"
              style={{
                left: '-8%',
                top: '-8%',
                visibility: this.props.dosyaVarmi ? 'visible' : 'hidden',
              }}
              //width="125"
              //height="125"
            />
            {this.props.hasDigitalBook ? (
              <div className="product-statistics">
                <ScoreBadge
                  icon="point"
                  title={`+${Math.round(
                    calc(productPrice, '*', activityType.Score),
                  )}  Puan`}
                  className="badge-score-custom mb-2"
                  value={calc(productPrice, '*', activityType.Score).toFixed(0)}
                />
                <ScoreBadge
                  icon="coin"
                  title={`+${Math.round(
                    calc(productPrice, '*', activityType.Coins) / 10,
                  ).toFixed(0)}  Jeton`}
                  className="badge-coin"
                  value={calc(productPrice, '*', activityType.Coins).toFixed(0)}
                />
              </div>
            ) : null}
          </div>

          <div className="card-book-operations">
            <div className="title">{this.props.name}</div>
            {!this.props.hasDigitalBook ? (
              <div
                className="alert alert-danger mb-0"
                // style={{ marginTop: -48 }}
              >
                Çalışma modu uyumlu değildir.
              </div>
            ) : (
              <div className="d-flex align-items-center mt-2">
                <form
                  className="input-container border-0 bg-f4f4f4 w-100"
                  onSubmit={this.activate}
                >
                  <input
                    type="text"
                    className="form-control font-weight-500 color-919191"
                    placeholder="Aktivasyon Kodu"
                    name={`book_${this.props.id}`}
                    ref={this.key}
                  />
                  <button
                    type="submit"
                    className="btn btn-blue w-25 border-radius-8"
                  >
                    <Icon icon="iPlusWhite" />
                  </button>
                </form>

                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  onClick={this.props.setShow}
                  className="ml-3 mr-2 color-919191 d-none d-sm-flex"
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const options = [
  { value: '0', label: 'Tüm Sınıflar', classId: 0 },
  { value: '2', label: '2. Sınıf', classId: 2 },
  { value: '3', label: '3. Sınıf', classId: 3 },
  { value: '4', label: '4. Sınıf', classId: 4 },
  { value: '5', label: '5. Sınıf', classId: 5 },
  { value: '6', label: '6. Sınıf', classId: 6 },
  { value: '7', label: '7. Sınıf', classId: 7 },
  { value: '8', label: '8. Sınıf', classId: 8 },
  { value: '9', label: '9. Sınıf', classId: 9 },
  { value: '10', label: '10. Sınıf', classId: 10 },
  { value: '11', label: '11. Sınıf', classId: 11 },
  { value: '12', label: '12. Sınıf', classId: 12 },
  { value: '94', label: 'YKS', classId: 94 },
];

class Books extends PureComponent {
  constructor(props) {
    super(props);

    var dataOfClass = null;
    for (let i = 0; i < options.length; i++) {
      if (options[i].classId === props.user.classId) {
        dataOfClass = options[i];
        break;
      }
    }

    this.state = {
      show: false,
      showModal: false,
      class: {
        value: dataOfClass ? dataOfClass.value : '',
        label: dataOfClass ? dataOfClass.label : '',
        classId: dataOfClass ? dataOfClass.classId : null,
      },
      bookName: '',
      showSuccess: false,
      aktivasyonAy: null,
    };

    const { groupId } = this.props.match.params;

    this.groupId = groupId;

    this.activationKey = React.createRef();
  }

  componentDidMount() {
    if (this.groupId) {
      this.props.getGroupBooks(this.groupId);
    } else {
      this.props.getPurchasedBooks();
    }
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  classFilter = (option) => {
    this.setState({ class: option });
  };

  closeSuccessModal = () => {
    this.setState({ showSuccess: false });
  };

  success = () => {
    this.setState({ showSuccess: true });
  };

  globalActivate = (e) => {
    e.preventDefault();
    const key = this.activationKey.current.value;
    this.activateBook(key);
  };

  activateBook = async (key) => {
    if (this.props.user.demo) {
      toast.error(
        'Bu işlemi DEMO modunda yapamazsınız. Devam etmek için giriş yapmanız gerekiyor.',
      );
      this.props.loginRequired(true);
      return;
    }
    if (key.length < 6) {
      toast.error('Aktivasyon kodu en az 6 haneli olmalıdır.');
      return;
    }

    this.props
      .activateBook(key)
      .then(async (response) => {
        if (response.data.status === false) {
          toast.error(response.data.errorMessage);
        } else {
          await this.props.getPurchasedBooks();
          this.setState({
            showSuccess: true,
            bookName: response.data.bookName,
            aktivasyonAy: response.data.aktivasyonAy,
          });
          if (this.state.target) {
            this.state.target.value = '';
          }
        }
      })
      .catch((err) => alert(err));
  };

  render() {
    const breadcrumbs = [
      { title: 'Anasayfa', url: '/dijitalkitap' },
      {
        title: 'Kütüphanem',
        active: this.groupId ? false : true,
        url: '/dijitalkitap/books',
      },
    ];

    let filteredBooks = [];
    if (this.groupId) {
      filteredBooks = this.props.books;
      filteredBooks.sort(
        (a, b) => new Date(b.lastEventDate) - new Date(a.lastEventDate),
      );
    } else {
      filteredBooks =
        this.state.class.value !== '0'
          ? this.props.books.filter(
              (b) =>
                b.class.indexOf(this.state.class.value) !== -1 ||
                b.class === '99',
            )
          : this.props.books;

      filteredBooks.sort(
        (a, b) => new Date(b.lastEventDate) - new Date(a.lastEventDate),
      );
    }
    const activityType = this.props.activityTypes.find(
      (x) => x.Name === 'activation',
    ) || { Score: 0, Coins: 0 };
    const { user } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <Breadcrumb items={breadcrumbs} />
          </div>
          <div className="col-12 mb-2 mb-sm-4 d-flex flex-column flex-sm-row justify-content-between">
            {!this.groupId ? (
              <div className="d-flex align-items-center">
                <form
                  className="input-container bg-f4f4f4 w-sm-100"
                  onSubmit={this.globalActivate}
                >
                  <input
                    type="text"
                    className="form-control fs-14"
                    placeholder="Aktivasyon Kodunu Gir"
                    name="general_activation"
                    ref={this.activationKey}
                  />
                  <button
                    type="submit"
                    className="btn btn-blue w-30 border-radius-8"
                  >
                    Aktif Et
                  </button>
                </form>

                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  onClick={() => {
                    this.setState({ showModal: true });
                  }}
                  className="ml-2 color-919191"
                />
              </div>
            ) : null}

            {/* 
                            <InformationModal
                                show={this.state.show}
                                onCloseClick={this.closeModal}
                            />
                        */}

            <SuccessModal
              //show={this.state.showSuccess}
              aktivasyonAy={this.state.aktivasyonAy}
              bookName={this.state.bookName}
              show={this.state.showSuccess}
              onCloseClick={this.closeSuccessModal}
            />

            {!this.groupId ? (
              <Select
                isSearchable={false}
                options={options}
                styles={customStyles}
                placeholder="Seçiniz"
                defaultValue={options.find((o) => o.classId === user.classId)}
                onChange={this.classFilter}
                className="w-100 w-sm-25 mt-2 mt-sm-0"
              />
            ) : null}
          </div>
        </div>

        <div className="row books-list">
          {filteredBooks.map((book, i) => {
            return book.isActivated ? (
              book.digitalGroupId === null || book.digitalGroupId === 0 ? (
                <div
                  key={`${book.digitalBookId}-${book.id}-${book.boughtDate}-${i}`}
                  className="col-6 col-lg-4 col-xl-3 mt-3"
                >
                  {book.hasDigitalBook ? (
                    <Book
                      {...book}
                      productPrice={this.productPrice}
                      activityType={activityType}
                    />
                  ) : (
                    <BookDark {...book} />
                  )}
                </div>
              ) : (
                <div
                  key={`${book.digitalBookId}-${book.id}-${book.boughtDate}-${i}`}
                  className="col-6 col-lg-4 col-xl-3 mt-3"
                >
                  <GroupBook {...book} />
                </div>
              )
            ) : (
              <div
                key={`${book.digitalBookId}-${book.id}-${book.boughtDate}-${i}`}
                className="col-6 col-lg-4 col-xl-3 mt-3"
              >
                <BookDark
                  {...book}
                  activityType={activityType}
                  onSuccess={this.success}
                  onClick={this.activateBook}
                  setShow={() => this.setState({ showModal: true })}
                />
              </div>
            );
          })}
        </div>

        <InformationModal
          show={this.state.showModal}
          onCloseClick={() => this.setState({ showModal: false })}
          list={[
            'kitapaktivasyonu_ADIM1.jpg',
            'kitapaktivasyonu_ADIM2.jpg',
            'kitapaktivasyonu_ADIM3.jpg',
            'kitapaktivasyonu_ADIM4.jpg',
          ]}
        />

        {/* <Modal
                    show={this.state.showModal}
                    onCloseClick={this.closeModal}
                    className="modal-container modal-md non-modal-height">
                    <div className="modal-header bt-dedede:50">
                        <CloseButton onClick={this.closeModal} />
                    </div>
                    <div className="modal-body">
                        <Slider {...settings}>
                            {[1, 2, 3, 4].map((image, index) => (
                                <div key={index}>
                                    <img
                                        src={require(`../../assets/images/images/kitapaktivasyonu_ADIM${image}.jpg`)}
                                        className="img-fluid w-100 carousel-img"
                                        alt=""
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div className="modal-footer fs-14 d-flex flex-column flex-sm-row justify-content-between w-100 text-left">
                        <div className="w-100">
                            <b>+90 212 963 35 35</b>
                            <span className="ml-2"></span>
                        </div>
                        <button
                            type="button"
                            className="btn btn-primary text-center text-capitalize mt-3 mt-sm-0 d-block d-sm-inline-block w-100"
                            onClick={this.closeModal}>
                            Tamam
                          </button>
                    </div>
                </Modal> */}

        {/* <div className="row mt-4 mb-3">
                    <div className="col-12">
                        <h3 className="color-484848">İlişkili Ürünler</h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4">
                        <BookDark/>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4">
                        <BookDark/>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4">
                        <BookDark/>
                    </div>
                </div> */}
      </>
    );
  }
}

Books.propTypes = {};

const mapStateToProps = ({ AccountReducer, StudyReducer }) => ({
  user: AccountReducer.user,
  activityTypes: StudyReducer.activityTypes,
  userBadges: StudyReducer.userBadges,
  books: StudyReducer.books,
});

const mapDispatchToProps = {
  getPurchasedBooks: actions.getPurchasedBooks,
  getGroupBooks: actions.getGroupBooks,
  activateBook: actions.activateBook,
  getBadges: actions.getBadges,
  getUserBadges: actions.getUserBadges,
  getScores: actions.getScores,
  loginRequired: accountActions.loginRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Books);
