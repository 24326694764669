import React, { useState, useEffect } from 'react';
import { getInstallments  } from "../../routes/actions/Payment";
import {
  Modal,
} from '../../components';
import CloseButton from '../../components/Button/Close';
import '../../assets/bootstrap/installment.scss';


function Installments ({total}) {
  var [showModal, setShowModal] = useState(false);
  const [bankInstallments, setBankInstallments] = useState([]);
  const bankLogos = [
    'AKBANK',
    'DENIZBANK',
    'FINANSBANK',
    'GARANTI',
    'ISBANK',
    'YKB',
    'ZIRAATBANK'
  ]

   useEffect(() => {
    const fetchInstallments = async () => {
      if (total > 0) {
        try {
          const i = await getInstallments(total);
          setBankInstallments(i);
        } catch (error) {
          console.error("Taksit bilgisi alınırken bir hata oluştu:", error);
        }
      }
    };
    fetchInstallments();
  }, [total]);
  return (
    <span className="w-100">
        <React.Fragment>
        <a
          href="#"
          className="fs-13 color-6e87ff"
          style={{
            display: 'inline-block',
            marginLeft: '10px',
            lineHeight: '1.8em',
            verticalAlign: 'top',
            clear: 'both',
            paddingLeft: '1px !important',
            fontSize: '13px',
            fontWeight: 600,
            WebkitUserSelect: 'none',
            userSelect: 'none'
          }}
           onClick={(e) => setShowModal(true)}
        >
          Taksit seçenekleri için tıkla
        </a>
        <Modal
          show={showModal}
          onCloseClick={() => setShowModal(false)}
          onHide={() => setShowModal(false)}
          className="custom-modal-dialog"
          >
              <div className="modal-header">
              <CloseButton onClick={() => setShowModal(false)} />
                <h5
                  className="modal-title font-weight-600 color-484848"
                  style={{ fontSize: 20, marginBottom:'0px' }}
                >Taksit seçenekleri</h5>
              </div>
              <div className="modal-body"  id="taksitSecenekleriModalBody">
              {bankInstallments.length > 0 ? (
                bankInstallments.map((bank, bankIndex) =>
                    bank.bank !== 'Taksit Bilgisi' ? (
                      <div className="taksitSecenekleriList">
                          {!bankLogos.includes(bank.bank) ? (
                            <div className="bank1" style={{
                              justifyContent: 'center',
                              display: 'flex'
                              }}
                            >
                            <div
                              className="bank-logo-placeholder"
                              style={{
                                width: '182px',
                                height: '46px',
                                backgroundColor: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #ccc',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '14px',
                                  color: '#000',
                                  textAlign: 'center',
                                  overflowWrap: 'break-word',
                                }}
                              >
                                {bank.bank}
                              </span>
                            </div>
                          </div>
                          ) : (
                          <div className="bank1">
                            <img
                              src={(`../../banks/${bank.bank}.png`)}
                              alt={bank.bank}
                              className="bank-logo"
                              style={{ maxHeight: '50px' }}
                            />
                          </div>
                          )}
                        <table>
                          <thead>
                              <tr>
                                <th style={{fontWeight : 'normal'}}>Taksit Tutarı</th>
                                <th style={{fontWeight : 'normal'}}>Toplam Tutar</th>
                              </tr>
                          </thead>
                          <tbody>
                            {bank.installments.map((installment, index) => (
                              <tr
                                key={index}
                                style={{
                                  fontWeight: installment.isBold == true ? 'bold' : 'normal',
                                }}
                              >
                                <td>
                                  {`${installment.no} X ${installment.monthly} TL`}
                                </td>
                                <td>{installment.total} TL</td>
                              </tr>
                            ))}
                            </tbody>
                        </table>
                      </div>
                    ) : null
                  )
              ) : (
                <p>Taksit bilgisi yükleniyor veya mevcut değil.</p>
              )}
            </div>
        </Modal>
        </React.Fragment>
      
    </span>
  );
}

export default Installments;