import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { loginWithJwtToken, mobilePay3d } from '../../routes/actions/Account';
// url  :
//      /odeme/mobile-3d/867704?year=2027&month=09&pan=5345380001828606&cvv=698&code=TD-lGx2HVuDejPaS2mpiog==&shippingOption=1&token=eyJhbGciOiJSUzI1NiIsImtpZCI6IkVGRkIxQTVBODQ4RUM0RjVBNzE2RTRCODUwQ0UzNUJBIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2NDM3MTU2NjksImV4cCI6MTY3NTI1MTY2OSwiaXNzIjoiaHR0cHM6Ly9pYW0udGVzdC5zeW5hcHNpcy5jb20udHIiLCJhdWQiOiJESy1BUEkiLCJjbGllbnRfaWQiOiJESy1BUEkiLCJzdWIiOiI0ODk3MjE1IiwiYXV0aF90aW1lIjoxNjQzNzE1NjY5LCJpZHAiOiJsb2NhbCIsImVtYWlsIjoieXNuNDdAZ2FsYXRhc2FyYXkubmV0IiwiZ3VpZCI6Ijg1MTkzMjk4LTkzZGItNDU5Yy04MmIwLWE1OTc5MGE2YTYzNiIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJJZGVudGl0eVNlcnZlckFwaSIsImVkaXRvciJdLCJqdGkiOiI5RTc4QjdGNzc1NEM0ODUzRERDOEM1ODJCNjQwODBEQiIsImlhdCI6MTY0MzcxNTY2OSwic2NvcGUiOlsiREstQVBJLVNDT1BFIiwiZW1haWwiLCJJZGVudGl0eVNlcnZlckFwaSIsIm9wZW5pZCIsInByb2ZpbGUiLCJvZmZsaW5lX2FjY2VzcyJdLCJhbXIiOlsicHdkIl19.iLBMe3AeA06RJdUW69siQUx8JAp_PCSGjgmm8lErUPA3hAAxdJmcfNxaAbVZdYsEU5uqu1lEPrEKXtz_2ZrKRxGGVwV9NoyAvb9mvsALRWOn5hWuqSfisJUYX6MUYjdJTuuLhkjfynQW5O-ygkZlPmePyyD-OQRNKMYC6k1hx1U5JwhVp1HShLG2Z63FW_OPKgT2P4Zxeiilp28jPYFflcrNrepKnDOa0wvEA0HsV1dH0_Y-jphyuFvw-ebe_WWW1WblGF_FtH_SoJYflf2Ub2Zhq7hT7eIsHdPUFqxpKzkIpX6MqfspeaxnMeKtliW2KHxgLElJdVNxZv9_HSsf5A
//      ysn47@galatasaray.net

function Mobile3dPayment(props) {
  const formElement = useRef(null);

  const [state, setState] = useState({
    formLoaded: false,
    error: {
      hasError: false,
      message: '',
    },
    payment: {},
  });

  const sendMessageToParent = (succees, message) => {
    let dataToSend = {
      success: succees,
      message: message,
    };
    window.mobile && window.mobile.postMessage(JSON.stringify(dataToSend));
    console.table({
      success: succees,
      message: message,
    });
  };

  const sendErrorMessage = (message) => {
    sendMessageToParent(false, message);
  };

  const sendSuccessMessage = (message) => {
    sendMessageToParent(true, message);
  };

  const getDataFromProps = () => {
    const { orderId } = props.match.params;
    //get token, month, year parameters from querystring
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('token');
    let code = urlParams.get('code');
    let shippingOption = urlParams.get('shippingOption');
    let pan = urlParams.get('pan');
    let cvc = urlParams.get('cvv');
    let month = urlParams.get('month');
    let paymentUrl = urlParams.get('paymentUrl');
    // append zero to month if it is single digit
    month = ('0' + month).slice(-2);

    //get last 2 digits of year from querystring, it could be 2 digits or 4 digits
    let year = urlParams.get('year');
    year = year && year.substring(year.length - 2, year.length);

    let appId = urlParams.get('appId') || 1; // 1 is TATS app id
    let installment = urlParams.get('installment') || 1;

    let couponIsReserved = urlParams.get('couponIsReserved') || false;
    let couponCode = urlParams.get('couponCode') || '';
    let isListIndirim = urlParams.get('isListIndirim') || '';
    let totalCardPoint = urlParams.get('totalCardPoint') || 0;


    return {
      orderId,
      token,
      month,
      year,
      pan,
      cvc,
      code,
      shippingOption,
      appId,
      installment,
      paymentUrl,
      couponIsReserved,
      couponCode,
      isListIndirim,
      totalCardPoint
    };
  };

  const isDataValid = (data) => {
    return !data.token ||
      !data.month ||
      !data.year ||
      !data.pan ||
      !data.cvc ||
      !data.token.length ||
      !data.month.length ||
      !data.year.length ||
      !data.pan.length ||
      !data.cvc.length ||
      !data.token.startsWith('ey') ||
      data.pan.length !== 16 ||
      data.cvc.length !== 3 ||
      data.month.length !== 2 ||
      data.year.length !== 2 ||
      !data.code ||
      !data.code.length
      ? false
      : true;
  };
  const data = getDataFromProps();

  useEffect(() => {
    const { orderId } = data;
    // check data if it is valid. if not, send error message
    if (!isDataValid(data)) {
      sendErrorMessage('Gönderilen bilgiler geçersiz.');
      return;
    }

    async function pay3d() {
      // login with jwt token
      const loginResponse = await props.loginWithJwtToken(data.token);

      // if login failed, send error message
      if (!loginResponse.success) {
        sendErrorMessage('Token ile giriş işlemi hatalı.');
        return;
      }

      //{ success, error, data }
      const pay3dResult = await props.mobilePay3d(orderId, data);
      if (!pay3dResult.success) {
        if (pay3dResult.error.status == 401) {
          sendErrorMessage('Kullanıcı bilgileri hatalı.');
        } else {
          let message = pay3dResult.error.statusText;
          sendErrorMessage(message);
        }
        setState({
          ...state,
          formLoaded: true,
          error: {
            hasError: true,
            message: 'Bir hata oluştu.',
          },
        });
        return;
      }

      if (pay3dResult.success === true) {
        if (pay3dResult.data.innerHtml) {
          // Prepared html.
          setState({
            ...state,
            formLoaded: true,
            payment: pay3dResult.data,
          });

          formElement.current.innerHTML = pay3dResult.data.attributes.html;
          formElement.current.submit();
        } else {
          setState({
            ...state,
            formLoaded: true,
            payment: pay3dResult.data,
          });
          setTimeout(() => {
            formElement.current.submit();
          }, 5000);
        }
      }
    }
    pay3d();
  }, []);

  return state.formLoaded == false ? (
    <div> Yükleniyor... </div>
  ) : state.error.hasError == true ? (
    <div>Hata Oluştu: {state.error.message}</div>
  ) : (
    <form
      action={state.payment.paymentUrl}
      method="post"
      ref={formElement}
      encType="application/x-www-form-urlencoded"
      style={{
        textAlign: 'center',
        padding: '20px',
      }}
    >
      {Object.keys(state.payment.attributes).map((name) => (
        <input
          type="text"
          name={name}
          defaultValue={state.payment.attributes[name]}
          key={name}
          hidden
        />
      ))}
      <span>Ödeme için yönlendiriliyorsunuz... </span> <br />
      <span>
        Yönlendirilmediyseniz
        <input
          type="submit"
          value="tıklayınız."
          style={{
            border: '0',
            background: 'transparent',
            fontWeight: 'bold',
          }}
        />
      </span>
    </form>
  );
}

const mapStateToProps = ({ AccountReducer, ProductReducer }) => ({});

const mapDispatchToProps = {
  loginWithJwtToken,
  mobilePay3d,
};

export default connect(mapStateToProps, mapDispatchToProps)(Mobile3dPayment);
